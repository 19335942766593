import './bootstrap';
import '../css/app.css';
import '../css/landing.css';
import 'floating-vue/dist/style.css'
import 'vue3-popper/';
import "vue-progressive-image/dist/style.css"; // <--- very important!
import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import FloatingVue from 'floating-vue'
import Notifications from '@kyvg/vue3-notification'
import InstantSearch from 'vue-instantsearch/vue3/es';
import Popper from "vue3-popper";
import VueProgressiveImage from 'vue-progressive-image'
import VueWriter from "vue-writer";
import * as Sentry from "@sentry/vue";

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

createInertiaApp({
    title: (title) => `${title}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        let app =  createApp({ render: () => h(App, props) });
        if (import.meta.env.APP_ENV === 'local') {
            app.config.devtools = true;
        }
        Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
            integrations: [
                Sentry.replayIntegration({
                    maskAllText: false,
                    blockAllMedia: false,
                }),
            ],
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });


        app.use(plugin)
            .use(VueWriter)
            .use(InstantSearch)
            .use(Notifications)
            .use(ZiggyVue)
            .use(FloatingVue)
            .component('popper', Popper)
            .use(VueProgressiveImage)
            .mount(el);
        return app;
    },
    progress: {
        color: '#4B5563',
    },
});
